import "./InventoriesHomePage.css";

import { InventoryStoragesSummary, InventorySummary } from "../../models/domain/inventory/InventorySummary";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, useCreateNotification, usePopup, useWindowResize } from "../../../../lib/infrastructure/ui/UIServices";

import { ReactComponent as AddIcon } from "../../../../lib/assets/icons/add.svg";
import { Button } from "../../../../lib/components/buttons/Button";
import { ReactComponent as CancelIcon } from "../../../../lib/assets/icons/close-icon.svg";
import { ColumnDefinition } from "../../../../lib/components/table/TableInterfaces";
import { ContextualMenu } from "../../../../lib/components/contextual-menu/ContextualMenu";
import { DateTime } from "luxon";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { ReactComponent as ExportIcon } from "../../../../lib/assets/icons/export.svg";
import { FullScreenLoader } from "../../../../lib/components/loader/FullScreenLoader";
import { IconButton } from "../../../../lib/components/buttons/IconButton";
import { InventoriesService } from "../../services/InventoriesService";
import { InventoryStatusEnum } from "../../models/dtos/InventoryDTOs/InventoryStatusEnum";
import { InventoryStatusTranslationTerms } from "../../models/domain/inventory/InventoryStatusEnum";
import { LocationChangedEvent } from "../../../../events/LocationChangedEvent";
import { ReactComponent as MoreActionsIcon } from "../../../../lib/assets/icons/more-actions.svg";
import { NewInventoryPopup } from "./popups/NewInventoryPopup";
import { Notification } from "../../../../lib/components/notifications/Notification";
import { PageLayout } from "../../../../lib/layouts/main-content/PageLayout";
import { Permission } from "../../../../models/api/gate/Permission";
import { ReactComponent as RemoveIcon } from "../../../../lib/assets/icons/remover-outline.svg";
import { ReactComponent as ReopenIcon } from "../../../../lib/assets/icons/reopen-inventory.svg";
import { ResponsiveDataTable } from "../../../../lib/components/table/ResponsiveDataTable";
import { ScalableIconButton } from "../../../../lib/components/buttons/ScalableIconButton";
import { Tag } from "../../../../lib/components/tag/Tag";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { getInventoryStatusCss } from "../../../../common/helpers/GetInventoryStatusCss";
import { translate } from "../../../../lib/infrastructure/i18n/InternationalizationService";
import { useGlobalLocation } from "../../../../lib/infrastructure/location/LocationServices";
import { useHasPermissions } from "../../../../lib/infrastructure/authorization/useHasLocationPermissions";
import { useNavigate } from "react-router-dom";
import { useServiceCallPro } from "../../../../services/UseServiceCall";
import { useServiceCallPro2 } from "../../../../lib/hooks/useServiceCall";

var inventoriesSvc = new InventoriesService();

export const topPermissions = [
    Permission.GLOBAL_ADMIN,
    Permission.LOCAL_ADMIN,
    Permission.FINANCES
];

export function InventoriesHomePage() {
    const navigate = useNavigate();
    const openPopup = usePopup();
    const closePopup = useClosePopup();
    const locationID = useGlobalLocation();
    const createNotification = useCreateNotification();
    const windowResize = useWindowResize();


    const [currentpage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [inventoriesData, setInventoriesData] = useState<InventorySummary[]>([]);
    const [totalItems, setTotalItems] = useState<number>(0);


    const { isLoadingPermissions, hasPermission } = useHasPermissions(topPermissions);

    const putInventoryStateCall = useServiceCallPro(inventoriesSvc.updateInventoryState);
    const [getDownloadReportUrl] = useServiceCallPro2(inventoriesSvc, inventoriesSvc.downloadUrlForInventoryExportReport);


    useEffect(() => {
        getInventoriesSummary();
    }, [currentpage, itemsPerPage]);


    useEffect(() => {
        const handleLocationChanged = () => {
            getInventoriesSummary();
        };
        LocationChangedEvent.attachHandler(handleLocationChanged);
        return () => { LocationChangedEvent.detachHandler(handleLocationChanged); }
    }, [locationID]);




    const getInventoriesSummary = useCallback(() => {

        if (!locationID) return;
        setIsLoading(true);

        inventoriesSvc.getInventories(`${locationID}`, currentpage, itemsPerPage)
            .then((data) => {
                setIsLoading(false);
                setInventoriesData(data.inventories);
                setTotalItems(data.totalItems);
            })
            .catch((error) => {
                setIsLoading(false);
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            })

    }, [locationID, currentpage, itemsPerPage, setIsLoading, setTotalItems, openPopup, setInventoriesData]);

    const handleOnExportButtonClicked = useCallback((id: number) => {
        if (!locationID || !id) return;
        getDownloadReportUrl(`${locationID}`, id.toString()).then(url => window.open(url, "_blank"));

    }, [getDownloadReportUrl, locationID]);

    const removeInventory = useCallback((id: number) => {
        if (!locationID) return;
        setIsLoading(true);

        inventoriesSvc.deleteInventory(`${locationID}`, id)
            .then((_) => {
                closePopup();
                createNotification(<Notification type="success" title={translate("COMMON.SYSTEMPOPUPS.Success")}
                    text={translate("INVENTORY.POPUP.RemoveInventorySuccessMessage")}></Notification>);
                getInventoriesSummary();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });
    }, [locationID, setIsLoading, closePopup, createNotification, getInventoriesSummary, openPopup]);


    const handleOnPageAndItemsChanged = useCallback(
        (items: number, currentPage: number) => {
            setItemsPerPage(items);
            setCurrentPage(currentPage);
        },
        [setItemsPerPage, setCurrentPage]
    );


    const reopenInventory = useCallback((v: InventorySummary) => {

        if (!locationID) return;

        putInventoryStateCall.invoke(`${locationID}`, `${v.id}`, { state: InventoryStatusEnum.ONGOING })
            .then((res) => {
                getInventoriesSummary();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, putInventoryStateCall.invoke, getInventoriesSummary]);


    const cancelInventory = useCallback((v: InventorySummary) => {

        if (!locationID) return;

        putInventoryStateCall.invoke(`${locationID}`, `${v.id}`, { state: InventoryStatusEnum.CANCELED })
            .then((res) => {
                getInventoriesSummary();
            })
            .catch((error) => {
                if (!error) return;
                openPopup(<ErrorPopup>{error.response.data.message}</ErrorPopup>);
            });

    }, [locationID, openPopup, putInventoryStateCall.invoke, getInventoriesSummary]);



    const columns: ColumnDefinition<InventorySummary>[] = useMemo(() => [
        {
            cellRenderProp: (v) => v.id,
            headerRender: "ID",
            width: "5%"
        },
        {
            cellRenderProp: (v) => v.name,
            headerRender: translate("INVENTORY.Name"),
            isMobilePrimaryCell: true,
            width: "50%"
        },
        {
            cellRenderProp: (v) => v.initialDate ? DateTime.fromISO(v.initialDate).toFormat("dd/MM/yyyy") : null,
            headerRender: translate("INVENTORY.InitialDate")
        },
        {
            cellRenderProp: (v) => v.finalDate ? DateTime.fromISO(v.finalDate).toFormat("dd/MM/yyyy") : null,
            headerRender: translate("INVENTORY.FinalDate"),
        },
        {
            cellRenderProp: (v) => (
                <Tag
                    text={translate(InventoryStatusTranslationTerms[v.status])}
                    backgroundColor={getInventoryStatusCss(v.status)}
                    isTiny={windowResize < 1024}
                />
            ),
            headerRender: translate("INVENTORY.Status"),
            isStatusCell: true
        },
        {
            cellRenderProp: (v) => {
                if (isLoadingPermissions || !hasPermission) return null;
                return <ContextualMenu
                    items={[
                        {
                            label: translate("INVENTORY.ReopenInventory"),
                            isVisible: (v.status === InventoryStatusEnum.CLOSED || v.status === InventoryStatusEnum.CANCELED) && (!isLoadingPermissions && hasPermission),
                            icon: <ReopenIcon />,
                            navigateTo: "",
                            onClick: () => {
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => reopenInventory(v)}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.ReopenInventory")}
                                    >
                                        {translate("INVENTORY.POPUP.ReopenInventoryConfirmMessage", v?.name || "")}
                                    </WarningPopup>
                                )
                            },
                        }, {
                            label: translate("INVENTORY.Export"),
                            isVisible: (v.status === InventoryStatusEnum.ONGOING || v.status === InventoryStatusEnum.CLOSED) && (!isLoadingPermissions && hasPermission),
                            icon: <ExportIcon />,
                            navigateTo: "",
                            onClick: () => {
                                handleOnExportButtonClicked(v.id);
                            },
                        }, {
                            isVisible: (v.status === InventoryStatusEnum.REGISTERED || v.status === InventoryStatusEnum.UNKNOWN) && (!isLoadingPermissions && hasPermission),
                            label: translate("COMMON.Remove"),
                            icon: <RemoveIcon />,
                            navigateTo: "",
                            onClick: () => {
                                if (!v.id) return;
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { removeInventory(v.id); }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.POPUP.RemoveInventory")}
                                        className="remove-inventory-popup"
                                    >
                                        {translate("INVENTORY.POPUP.RemoveInventoryConfirmText", v.name)}
                                    </WarningPopup>
                                );
                            }
                        }, {
                            label: translate("INVENTORY.CancelInventory"),
                            isVisible: (v.status === InventoryStatusEnum.REGISTERED || v.status === InventoryStatusEnum.ONGOING) && (!isLoadingPermissions && hasPermission),
                            icon: <CancelIcon />,
                            navigateTo: "",
                            onClick: () => {
                                openPopup(
                                    <WarningPopup
                                        onDismissClicked={() => { closePopup(); }}
                                        onConfirmClicked={() => { cancelInventory(v); }}
                                        dismissText={translate("COMMON.Cancel")}
                                        confirmText={translate("INVENTORY.POPUP.CancelInventory")}
                                        className="cancel-inventory-popup"
                                    >
                                        {translate("INVENTORY.POPUP.CancelInventoryConfirmMessage", v.name)}
                                    </WarningPopup>
                                )
                            },
                        },
                    ]}
                >
                    <ScalableIconButton size={40} icon={<MoreActionsIcon />} />
                </ContextualMenu>
            },
            columnKey: "moreActions",
            width: "24px",
            isMobileHeaderIcon: true
        },
    ], [removeInventory, reopenInventory, cancelInventory, closePopup, openPopup, windowResize, isLoadingPermissions, hasPermission]);


    const renderActionBtns = useMemo(() => {
        if (isLoadingPermissions || !hasPermission) return null;
        if (windowResize > 768)
            return <Button text={translate("INVENTORY.NewInventory")} type="primary" onClick={() => openPopup(<NewInventoryPopup onCompletedOperations={getInventoriesSummary} locationID={"" + locationID} />)} />;
        else
            return <IconButton type="primary" icon={<AddIcon />} onClick={() => openPopup(<NewInventoryPopup onCompletedOperations={getInventoriesSummary} locationID={"" + locationID} />)} />;
    }, [windowResize, getInventoriesSummary, locationID, openPopup, isLoadingPermissions, hasPermission]);


    return (
        <PageLayout
            tabTitle={"Valmet - " + translate("INVENTORY.Inventory")}
            pageTitle={translate("INVENTORY.InventoryPlural")}
            className="inventories-home-page"
            showBreadcrumb
            actions={renderActionBtns}
        >
            {putInventoryStateCall.isLoading ? <FullScreenLoader /> : null}
            <ResponsiveDataTable
                className="inventories-table"
                columnDefinitions={columns}
                items={inventoriesData || []}
                totalitems={totalItems || 0}
                isLoading={isLoading}
                onClickRow={(inventory) => navigate(`${inventory.id}/storages`)}
                paginationOptions={{ itemsPerPage: itemsPerPage, itemsPerPageOptions: [10, 20, 30, 40, 50] }}
                currentpage={currentpage}
                onPageAndItemsChanged={handleOnPageAndItemsChanged}
            ></ResponsiveDataTable>
        </PageLayout >
    );
}



function renderInventorySummaryTags(summary: InventoryStoragesSummary | null) {
    return <div className="summary-tags">
        {summary?.confirmed ?
            <Tag
                className="confirmed-counts"
                backgroundColor="status-lime-green"
                text={summary.confirmed.toString()}
            /> : null}
        {summary?.onGoing ?
            <Tag
                className="on-going-counts"
                backgroundColor="status-yellow"
                text={summary.onGoing.toString()}
            /> : null}
        {summary?.finished ?
            <Tag
                className="finished-counts"
                backgroundColor="status-green"
                text={summary.finished.toString()}
            /> : null}
    </div>
}
